import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js'
import EditProductCard from '../edit-product-card';
import {getAllSpecials} from '../../../api-calls/specials-api';
import Loading from '../loading.js';

class EditInventory extends Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = { 
            allProducts:[],
            isUpdated:false,
            isLoading:false,
        };

        this.generateSpecialCard = this.generateSpecialCard.bind(this);
       
      }

      componentDidMount(){
        //retrieve list of all products
        this.getAllSpecials();
      }


      componentDidUpdate(prevProps) {
          console.log(prevProps.updatedCount);
        if(prevProps.updatedCount !== this.props.updatedCount) {
            this.getAllSpecials();
        }
      }

      getAllSpecials = () =>{
        this.setState({isLoading:true});
        getAllSpecials().then(
                res => {
                    this.setState({allProducts:res.data});
                    this.setState({isLoading:false});
                }
                ).catch(error=> {
                    this.setState({isLoading:false});
                    console.log(error);
                }
        );
      }


      generateSpecialCard(){       
        return (
            <React.Fragment>
                {this.state.allProducts.map((product, index) => (
                    <EditProductCard title={product.name} 
                                    description = {product.description}
                                    id={product.id}
                                    key={product.name+index}>
                    </EditProductCard>
                ))}
            </React.Fragment>
        );        
      }


      generateTable=()=>{


        if(this.state.isLoading){
            return(<div>
                        <Loading></Loading>
                    </div>);
        }else{
            return(<div>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Index</th>
                            <th scope="col">Description</th>
                            <th scope="col">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.generateSpecialCard()}
                    </tbody>
                </table>
            </div>);
            
        }

      }
      
    render() {

        const backgroundCSS = {
            width: '100%'
        };

        return (
            <div>
                {/*New Content Here*/}
                <div className="d-flex">
                    <div>
                        <h1>Update Specials</h1>
                        <h4></h4>
                    </div>
                </div>
                <div className="card shadow p-4" style={backgroundCSS}>

                    {this.generateTable()}
                    
                </div>
            </div>
        );
    }
}

export default EditInventory;
