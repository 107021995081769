import React, {Component} from 'react';

/* Imports Bootsrap libraries */
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'


class Loading extends Component {
    render() {

        const itemCSS = {
            fontSize: '120px',
            color: 'red'
        };

        return (
              
                    <div className="d-flex flex-column  justify-content-center align-items-md-start align-items-center p-2">
                            <div>
                               
                                 <FontAwesomeIcon style={itemCSS} icon={faCircleNotch} spin /> 
                            </div>
                    </div>
                
        );

    }
}

export default Loading;
