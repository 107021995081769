import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LogOutIcon from '@material-ui/icons/Input';
import { mainListItems, secondaryListItems } from '../components/dashboard/customer-themes';
import SimpleLineChart from '../components/graphs/SimpleLineChart';
import SimpleTable from '../components/graphs/SimpleTable';
import { connect } from 'react-redux';
import {setFilter} from '../redux/actions';



const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title:{
    flexGrow: 1,
    color: 'white',
    '&:hover': {
      color: 'white',
      'text-decoration': 'none'
    }
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
});

class Dashboard extends React.Component {
  state = {
    open: false,
    currentBody: "dashboard"
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  renderBody =(classes) =>{

      const backgroundCSS = {
          width: '50%'
      };

      const buttonCSS = {
          width: '100%'
      };

      const textAreaCSS = {
          resize: 'none'
      };

    if(this.props.activeFilter === "dashboard"){
        return(
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Typography variant="h4" gutterBottom component="h2">
            Orders
          </Typography>
          <Typography component="div" className={classes.chartContainer}>
            <SimpleLineChart />
          </Typography>
          <Typography variant="h4" gutterBottom component="h2">
            Products
          </Typography>
          <div className={classes.tableContainer}>
            <SimpleTable />
          </div>
        </main>);
     } else if(this.props.activeFilter === "add_product"){

       return (<main className={classes.content}>
         <div className={classes.appBarSpacer} />
         <Typography variant="h4" gutterBottom component="h2">
           Product Configuration
         </Typography>

         <div className={classes.tableContainer} class="mb-2">
             <div class="card shadow p-4" style={backgroundCSS}>
               <form>
                   <legend>Add Product</legend>
                   <div class="form-group mb-2">
                      <input type="text" class="form-control" id="name" placeholder="Enter Name..."/>
                    </div>
                    <div class="form-group mb-2">
                       <input type="text" class="form-control" id="info" placeholder="Enter Info..."/>
                     </div>
                     <div class="form-group mb-2">
                        <input type="text" class="form-control" id="price" placeholder="Enter Price..."/>
                      </div>
                   <div class="form-group mb-2">
                       <select name="category" class="custom-select">
                          <option selected>Select Category...</option>
                          <option value="new-arrivals">New Arrivals</option>
                          <option value="tractors">Tractors</option>
                          <option value="utvs">UTVs</option>
                          <option value="construction">Construction</option>
                          <option value="riding-mower">Riding Mowers</option>
                          <option value="3-point-attachments">3-Point Attachments</option>
                          <option value="miscellaneous">Miscellaneous</option>
                        </select>
                    </div>
                   <div class="form-group mb-2">
                       <select name="brand" class="custom-select">
                          <option selected>Select Brand...</option>
                          <option value="john-deere">John Deere</option>
                          <option value="kubota">Kubota</option>
                          <option value="new-holland">New Holland</option>
                          <option value="misc">Other</option>
                        </select>
                    </div>
                   <div class="form-group custom-file mb-2">
                       <input type="file" class="custom-file-input" id="customFile"/>
                       <label class="custom-file-label" for="customFile">Upload Images...</label>
                       {/*Add Button Here?*/}
                   </div>
                   <div class="form-group mb-2">
                      <textarea class="form-control" rows="5" id="comment" placeholder="Enter Description..." style={textAreaCSS}></textarea>
                    </div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input type="checkbox"/>
                        </div>
                      </div>
                      <input type="text" class="form-control" placeholder="Enter Ebay URL..."/>
                    </div>
                    {/*<div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input type="checkbox"/>
                        </div>
                      </div>
                      <input type="text" class="form-control" placeholder="Enter Criag's List URL..."/>
                    </div>*/}
                    {/*<div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input type="checkbox"/>
                        </div>
                      </div>
                      <input type="text" class="form-control" placeholder="Enter Facebook URL..."/>
                    </div>*/}
                    <br/>
                   <button type="submit" class="btn btn-primary" style={buttonCSS}>Finish</button>
               </form>
           </div>
       </div>

       </main>);
     }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
              >
              <Link to="/home" className={classes.title}>BWS</Link>
            </Typography>

            <IconButton color="inherit">

                <LogOutIcon />

            </IconButton>

          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />

        </Drawer>

        {this.renderBody(classes)}

      </div>
    );
  }

}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    activeFilter: state.dashboardFilter.filter
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));
