import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js'
import Form from "react-jsonschema-form";
import 'react-dropzone-uploader/dist/styles.css';
import '../css/global.css';

class ContractPage extends Component {

    render() {
        const companyName="Divvision33 LLC"
        const customerName="Bullard's American Cafe"
        const developmentPrice=250;
        const hostingPrice=13;

        return (
            <div>
                <div class="d-flex flex-column">
                    <div class="p-4">
                        <div>
                            <h1>Customer Contract</h1>
                        </div>
                        <div class="card shadow p-4 w-100">
                            <div>
                                <h3>Summary</h3>
                                <p>This contract is between us <span class="keyword-divv">{companyName}</span> and you <span class="keyword">{customerName}</span></p>
                                <p>We will always do our best to fulfill your needs and meet your goals, but sometimes it is best to have a few simple things written down so that we both know what is what, who should do what and what happens if stuff goes wrong. In this contract you won't find complicated legal terms or large passages of unreadable text. We have no desire to trick you into signing something that you might later regret. However, we do want what's best for the safety of both parties, now and in the future.</p>
                            </div>
                            <div>
                                <h3>In short</h3>
                                <p>You <span class="keyword">{customerName}</span> are hiring us <span class="keyword-divv">{companyName}</span> to design your website for the estimated total price of <span class="keyword-price">${developmentPrice.toFixed(2)}</span> as outlined in our previous correspondence.</p>
                                <p>What do both parties agree to do?</p>
                                <p>As our customer, you have the power and ability to enter into this contract on behalf of your company or organization. You agree to provide us with everything that we need to complete the project including text, images and other information as and when we need it and in the format that we request. You agree to review our work, provide timely feedback and sign-off approval. Deadlines work two ways and you will also be bound by any dates that we set together. You also agree to stick to the payment schedule set out at the end of this contract.</p>
                                <p>We have the experience and ability to perform the services you need from us and we will carry them out in a professional and timely manner. Along the way we will endeavor to meet all the deadlines set but we can't be responsible for a missed launch date or deadline if you have been late in supplying materials or have not approved or signed off our work on time at any stage. On top of this we will also maintain the confidentiality of any information that you give us.</p>
                            </div>
                            <div>
                                <h3>Design</h3>
                                <p>We will create designs for the look-and-feel, layout and functionality of your web site. We agree to work with you until we have created a design you love with unlimited revisions. Once both parties have signed-off on the completion of the design no further design revisions can be made. If you're not happy with the designs at any later stage, you will pay us in full for all of the work that we have produced until that point and you may either cancel this contract or continue to commission us to make further design revisions based on a newly negotiated estimate/contract.</p>
                            </div>
                            <div>
                                <h3>Desktop Browser Testing</h3>
                                <p>We test our work in current versions of major desktop browsers including those made by Apple (Safari), Google (Chrome), Mozilla Firefox and Opera. We won’t test in other older browsers or Internet Explorer unless you specify otherwise. If you need an enhanced design for an older browser, we can provide a separate estimate for that.</p>
                            </div>
                            <div>
                                <h3>Mobile Browser Testing</h3>
                                <p>Testing popular small-screen devices is essential in ensuring that a person’s experience of a design is appropriate to the capabilities of the device they’re using. We test our work in:</p>
                                <p>iOS: Safari, Google Chrome Android 4.x, Google Chrome, and Firefox</p>
                                <p>We currently don’t test Blackberry, Opera Mini/Mobile, Windows Phone or other mobile browsers.
                                If you need us to create a separate mobile design, we can provide an estimate for that.</p>
                            </div>
                            <div>
                                <h3>Text Content</h3>
                                <p>We’re not responsible for writing or inputting any text copy. If you’d like us to write new content or input text for you, we can provide a separate estimate for that.</p>
                            </div>
                            <div>
                                <h3>Photographs</h3>
                                <p>You should supply graphic files in an editable, vector digital format. You should supply photographs in a high-resolution digital format. If you choose to buy stock photographs, we can suggest stock libraries. If you’d like us to search for photographs for you, we can provide a separate estimate for that.</p>
                            </div>
                            <div>
                                <h3>Changes and Revisions</h3>
                                <p>We know from plenty of experience that fixed-price contracts are rarely beneficial to you, as they often limit you to your first idea about how something should look, or how it might work. We don't want to limit either your options or your opportunities to change your mind.</p>
                                <p>The estimate/quotation prices at the beginning of this document are based on the number of days that we estimate we'll need to accomplish everything that you have told us you want to achieve. If you do want to change your mind, add extra pages or templates or even add new functionality, that won't be a problem. However, all scenarios are unique so a new price and completion deadline will be negotiated at that time to accommodate any new changes. Along the way we might ask you to put requests in writing so we can keep track of changes.</p>
                            </div>
                            <div>
                                <h3>Hosting/Maintenance/Technical Support</h3>
                                <p>We offer web hosting via VPS (Virtual Private Server). We would prefer that you would host with us for a minimum of 12 months as this is the only true way to get the most and best out of our software.</p>
                            </div>
                            <div>
                                <h3>Other Hosting</h3>
                                <p>However you may already have professional web site hosting, you might even manage that hosting in-house; if that's the case, great. We will charge you a one-off fee for installing your site on this server. However we are unable to guarantee that the third-party/other hosting service’s servers are properly configured and have the appropriate resources to properly handle any software installed.</p>
                            </div>
                            <div>
                                <h3>Transfer Terms</h3>
                                <p><span class="keyword-divv">{companyName}</span> is not responsible for any downtime due to transferring your website to our hosting service. We are not responsible for current design, software, architecture, copyrighted material or any pre-existing problems of a website being transferred as it was not produced by <span class="keyword-divv">{companyName}</span>. However we are willing to work out any current issues pending new agreements.</p>
                            </div>
                            <div>
                                <h3>Legal Stuff</h3>
                                <p>We can't guarantee that the functions contained in any web page templates or in a completed website will always be error-free and so we can't be liable to you or any third-party for damages, including lost profits, lost savings or other incidental, consequential or special damages arising out of the operation of or inability to operate this web site and any other web pages, even if you have advised us of the possibilities of such damages.</p>
                                <p>If any provision of this agreement shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this agreement and shall not affect the validity and enforceability of any remaining provisions.</p>
                            </div>
                            <div>
                                <h3>Copyrights</h3>
                                <p>First, you guarantee that all elements of text, images or other artwork you provide are either owned by your good selves or that you’ve permission to use them.</p>
                                <p><span class="keyword-divv">{companyName}</span> is not responsible for copyright issues/other issues that may potentially arise from using images without consent or ownership.</p>
                                <p>Then, when you’re final payment has cleared; copyright will be automatically assigned as follows:</p> 
                                <p>You’ll own the visual elements that we create for this project. We’ll give you source files and finished files and you should keep them somewhere safe as we’re not required to keep a copy. You own all elements of text, images and data you provided, unless someone else owns them.</p>
                                <p>We’ll own the unique combination of these elements that constitutes a complete design and we’ll license its use to you, exclusively and in perpetuity for this project only, unless we agree otherwise.</p>
                                <p>We love to show off our work and share what we’ve learned with other people, so we reserve the right to display a link to and from your website as part of our portfolio and to write about it on websites, in magazine articles and in books.</p>
                            </div>
                            <div>
                                <h3>Payments Section</h3>
                                <p>We are sure you understand how important it is as a small business that you pay the invoices that we send you promptly. As we're also sure you'll want to stay friends, you agree to stick tight to the following payment schedule(s).</p>
                            </div>
                            <div>
                                <h3>Design/Development Payment Schedule</h3>
                                <p>By signing this legally binding contract you agree to pay <span class="keyword-price">${developmentPrice.toFixed(2)}</span> on completion of your website.</p>
                            </div>
                            <div>
                                <h3>Hosting Payment Schedule</h3>
                                <p>As previously discussed, if you choose to host with <span class="keyword-divv">{companyName}</span> you agree to pay <span class="keyword-price">${hostingPrice.toFixed(2)}</span> per year starting on July 1st, 2017.</p>
                                <p>Hosting will automatically renew until notified otherwise.</p>
                            </div>
                            <div>
                                <h3>Hosting Late Fees Payment</h3>
                                <p>As previously discussed, if you choose to host with <span class="keyword-divv">{companyName}</span> you agree to pay a flat fee of <span class="keyword-price">n/a</span> every month your payment is past due.</p>
                            </div>
                            <div>
                                <h3>Transfer Payment Schedule</h3>
                                <p>As previously discussed, if you choose to transfer services to <span class="keyword-divv">{companyName}</span> you agree to pay <span class="keyword-price">n/a</span> on completion of service.</p>
                                <p>Just like a parking ticket, you can’t transfer this contract to anyone else without our permission. This contract stays in place and need not be renewed. If for some reason one part of this contract becomes invalid or unenforceable, the remaining parts of it remain in place.</p>
                                <p>Although the language is simple, the intentions are serious and this contract is a legal document under exclusive jurisdiction of [English and Welsh] courts.</p>
                            </div>    
                            <div>
                                {/* [TODO]::Mike Fix This!! */}
                                <h3>Signature</h3>
                                <p>____________________</p>
                                <p>____________________</p>
                                <p>____________________</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>          
        );
    }
}
export default ContractPage;
