import React, {Component} from 'react';
import { Link, withRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import {authUser} from '../../api-calls/auth';

class LoginCard extends Component {
    constructor(props){
        super(props);
        this.state = {
              name: '',
              pwd: '',
              loginError:false
       }
        this.submitForm = this.submitForm.bind(this);

    }

    submitForm(evt){

         evt.preventDefault();
         authUser(this.state.name,this.state.pwd).then(

           success => {
                this.setState({loginError:false});
                this.props.history.push("/dashboard-new");

          },
           error => {
                this.setState({loginError:true});
           }
         )

    }


    changeHandler = evt => {
      const name = evt.target.name;
      const value = evt.target.value;

      this.setState({
           [name]: value,
           loginError: false
      });
    }

    render() {
        const backgroundCSS = {
          //background: 'red'
        };

        const cardCSS = {
            width: '400px'
        };

        const logoCSS = {
            width: '100%'
        };

        const buttonCSS = {
            width: '100%'
        };

        const warningCSS = {
          width: '75%'
        }

        return (
            <div className="card shadow-lg" style={cardCSS}>
                <Link to="/home" className="nav-link">
                  <img className="card-img-top mx-auto d-block pt-4 w-75" src="img/bullards-logo.png" alt="Bullards Logo"/>
                </Link>

                <div className="card-body">
                  {this.state.loginError ? (
                    <div className="alert alert-danger" role="alert">
                       Invalid Username or Password.
                    </div>
                  ) : (
                    <span></span>
                  )}

                    <form onSubmit={this.submitForm}>
                        <div className="form-group">
                            <label className="font-weight-bold">Username:</label>
                            <input   onChange={this.changeHandler} className="form-control" name="name"
                              type="text" />
                        </div>
                        <div className="form-group">
                            <label className="font-weight-bold">Password:</label>
                            <input onChange={this.changeHandler} className="form-control" name="pwd" type="password" />
                        </div>
                        <button className="btn btn-primary" type="submit" value="Submit" style={buttonCSS}>Login</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default withRouter(LoginCard);
