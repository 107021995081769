import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../components/dashboard/css/dashboard.css';
import { mainListItems, mobileMenu } from '../components/dashboard/customer-themes';
import OverviewPage from '../components/dashboard/pages/overview-page';
import SpecialsPage from '../components/dashboard/pages/specials-page';
import ReviewPage from '../components/dashboard/pages/review-page';
import ProfilePage from '../components/dashboard/pages/profile-page';
import CateringPage from '../components/dashboard/pages/catering-page';
import ContractPage from '../components/dashboard/pages/contract-page';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { Link, withRouter } from "react-router-dom";
import {logOut} from '../api-calls/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

class DashboardNew extends Component {

  constructor(props) {
    super(props);
    this.state = { counter: 0,
                   username:null};
    this.renderBody = this.renderBody.bind(this);
  }

    renderBody () {
        if(this.props.activeFilter === "overview"){
            return (<OverviewPage></OverviewPage>);
        } else if(this.props.activeFilter === "specials"){
            return (<SpecialsPage></SpecialsPage>);
        } else if(this.props.activeFilter === "reviews"){
            return (<ReviewPage></ReviewPage>);
        } else if(this.props.activeFilter === "profile"){
            return (<ProfilePage></ProfilePage>);
        } else if(this.props.activeFilter === "catering"){
            return (<CateringPage></CateringPage>);
        } else if(this.props.activeFilter === "contract"){
            return (<ContractPage></ContractPage>);
        } else if(this.props.activeFilter === "logout"){
            logOut();
            this.props.history.push('/');
 
        }else{
            /*DEFAULT PAGE HERE */
            return (<OverviewPage></OverviewPage>);
        }
    }

  render () {

    const baseCSS = {
        minHeight:'100vh'
    };

    const sideNavCSS = {
        width: '15%',
        backgroundColor: '#d33939',
        overflowX: 'hidden',
        minHeight:'100vh',
        minWidth: '225px'
    };

    const mainContentCSS = {
        width: '100%',
        background: '#F8F8FF'
    };

    const mainContentNavCSS = {
        background: 'white',
        height: '60px',
        width: '100%'
    };

    const boxCSS = {
        background: 'dimGrey',
        width: '100%'
    };

    const testCSS = {
        width: '200px',
        textAlign: 'center',
        borderLeft: '2px solid lightGrey'
    };

    const imageCSS = {
        //
    };

    const profileCSS = {
        height: '70px',
        background: 'white',
        paddingTop : '10px',
        borderBottomColor: 'black',
        borderBottomWidth: 1
    };

    const redCSS = {
        background: 'red'
    };

    const barsCSS = {
        fontSize: '25px'
    };

    const menuCSS = {
        background: 'white'
    };

    const topNavCSS = {
        height: '80px'
    };

    const mobileMenuCSS = {
        border: 'none',
        width: '100vh'
    };

        return (
            <div>

                {/* TOP NAV */}
                <nav class="navbar navbar-light bg-white shadow" style={topNavCSS}>
                    <a class="navbar-brand h-100" href="#">
                        <img src="img/bullards-logo.png" class="d-block h-100" alt="Bullards Logo"/>
                    </a>
                    <div class="pr-2">
                        <h2><FontAwesomeIcon icon={faBars}/></h2>
                    </div>
                </nav>

                <div className="d-flex" style={baseCSS}>               

                    {/* SIDE NAV */}
                    <div name="side-nav" className="d-none d-sm-block shadow" style={sideNavCSS}>
                        <div>{mainListItems}</div>
                    </div>

                    {/* MAIN */}
                    <div name="main-content" class="w-100 bg-white">
                        {/* MOBILE NAV */}
                        <div className="d-block d-sm-none nav-item dropdown position-static" style={menuCSS}>
                            <div className="nav-link" data-toggle="dropdown">
                                <Link to="/" className="nav-link">
                                    <div className="d-flex justify-content-between">
                                        <div><span>Dashboard</span></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dropdown-menu" style={mobileMenuCSS}>
                                <div>{mobileMenu}</div>
                            </div>
                        </div>
                        <div>{this.renderBody()}</div>
                    </div>
                </div>
            </div>
        );
    }
}


DashboardNew.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    activeFilter: state.dashboardFilter.filter
  };
};

export default connect(mapStateToProps)(withRouter(DashboardNew));
