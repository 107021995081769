import axios from 'axios';
import {createAuthorizationTokenHeader} from './auth';

const protocol="https://";
const url="rambl.in";
const port ="8333";

/**
* Authorize user
**/
export const getAllSpecials = () => {

  const req = axios.get(protocol+url+':'+port+'/bullards-api/getAllSpecials',
              {
                headers: createAuthorizationTokenHeader()
              }
  ).then(res => {
        return res;
    }
  ).catch(error=> {
    // handle error
    throw error;
  })
  return req
}

export const removeSpecials = (idNum) => {

  const req = axios.get('https://rambl.in:8333/bullards-api/removeSpecials/'+idNum,
              {
                headers: createAuthorizationTokenHeader()
              }
  ).then(res => {
        return res;
    }
  ).catch(error=> {
    // handle error
    throw error;
  })
  return req
}