import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js'
import {getUserDetails} from '../../../api-calls/user-api';
import Gravatar from '../Gravatar';
import Loading from '../loading.js';
//getUserDetails 
//
class ProfilePage extends Component {
    constructor(props){
        super(props);
        this.state = {
              result: null,
              isLoading: true
       }
    }

    componentDidMount() {
       
        
        getUserDetails().then(
            res => {
                console.log(res);
                this.setState({result : res.data.result, isLoading:false});
            }
            ).catch(error=> {
                this.setState({result : null, isLoading:false});
            }
        )
    }
    /**
     * Tyler Format these results properly
     */
    renderPersonalInfo = () =>{
        const personalInfo = JSON.parse(this.state.result);
        if(this.state.isLoading === true){
            return(<div>
                        <Loading/>
                    </div>)
        }else{
            if(personalInfo!==undefined && personalInfo!==null){
                return(<div>
                            
                            <span className="smallNote clearFix">Update Profile Picture at <a href="https://gravatar.com">http://gravatar.com</a></span>
                            <p></p>
                            <p>
                            Username: {personalInfo.username}
                            </p>
                            <p>
                            First Name: {personalInfo.firstName}
                            </p>
                            <p>
                            Last Name: {personalInfo.lastName}
                            </p>

                            <p>
                            Email: {personalInfo.email}
                            </p>
                            <p>
                                Last Password Reset Date : {personalInfo.lastPasswordResetDate}
                            </p>

                            
                    </div>)
            }else{
                    return(<div>
                            Error Retrieving Profile Data
                        </div>)
            }
        }
        
    }

    render() {
        const smBoxCSS = {
            background: 'dimGrey',
            height: '150px',
            width: '100%',
            textAlign: 'center',
            borderRadius: '12px'
        };

        const mdBoxCSS = {
            background: 'dimGrey',
            minHeight: '250px',
            width: '100%',
            textAlign: 'center',
            borderRadius: '12px'
        };

        const lgBoxCSS = {
            background: 'dimGrey',
            minHeight: '500px',
            width: '100%',
            textAlign: 'center',
            borderRadius: '12px'
        };

        return (
            <div>
                <div class="d-flex flex-column">
                    <div class="p-4">
                        <div>
                            {/* <h1>Profile</h1> */}
                        </div>
                        <div class="card shadow p-4 w-100">
                            <div class="mx-auto w-50 pb-4">
                                <h3>Personal Info </h3> <Gravatar width="100px" height="100pc"/>
                                
                                <div>{this.renderPersonalInfo()}</div>
                            </div>
                            {/* <div class="mx-auto w-50">
                                <h3>Update Password</h3> 
                                <form ng-submit="" class="form-horizontal" role="form"> */}

                                    {/* <!-- MIKE PLEASE SHOW HIDE THESE MESSAGE BOXES --> */}
                                    {/* <div ng-show="passedUpdate" class="col-lg-12">
                                        <div class="alert alert-success alert-dismissable">
                                            <span class="close" data-dismiss="alert" aria-label="close"><i class="fa fa-times-circle" aria-hidden="true" ng-click="hidePassed()"></i></span>
                                        </div>
                                    </div> */}
                                    
                                    {/* <!-- MIKE PLEASE SHOW HIDE THESE MESSAGE BOXES --> */}
                                    {/* <div ng-show="failedUpdate" class="col-lg-12">
                                        <div class="alert alert-danger alert-dismissable">
                                            <span class="close" data-dismiss="alert" aria-label="close"><i class="fa fa-times-circle" aria-hidden="true" ng-click="hideFailed()"></i></span>
                                        </div>
                                    </div> */}
                                    
                                    {/* <div ng-click="showPass()" ng-show="updatePass" style="margin-bottom:15px;">
                                        <a href="javascript:void(0);">
                                            Change Password <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </a>
                                    </div> */}
                                    
                                    {/* <div ng-hide="updatePass">
                                        <div class="form-group">
                                            <label class="control-label" for="password">Old Password</label>
                                            <div>
                                                <input ng-model="formData.oldPass" type="password" class="form-control" required/>
                                            </div>
                                        </div> */}

                                        {/* <div class="form-group">
                                            <label class="control-label" for="textinput">New Password</label>
                                            <div>
                                                <input id="pwd" ng-model="formData.newPass" type="password" class="form-control" required/> */}
                                                {/* <!--<div ng-show="pwdError">{{errorMsg}}</div>--> */}
                                            {/* </div>
                                        </div> */}

                                        {/* <div class="form-group">
                                            <label class="control-label" for="textinput">Confirm Password</label>
                                            <div>
                                                <input id="pwdConf" ng-model="formData.newPassConf" type="password" class="form-control" required/>
                                            </div>
                                        </div> */}

                                        {/* <!--<h6>{{updatePassMsg}}</h6>--> */}

                                        {/* <div class="form-group">
                                            <div class="text-center">
                                                <button ng-click="changePassword()" class="btn btn-success">Update Password</button>
                                            </div>
                                        </div>
                                    </div>    */}
                                {/* </form> */}
                            </div>
                            <div>                    
                                <form ng-submit="updatePersonal()" class="form-horizontal" role="form">
                                    <fieldset> 
                                                                          
                                        {/* <div class="form-group">
                                            <label class="col-sm-3 control-label" for="textinput">Email</label>
                                            <div class="col-sm-8">
                                                <input id="email" ng-model="formData.email" ng-value="userInfo.EMAIL" type="text" class="form-control" required/>
                                            </div>                                         
                                        </div> */}                                                                  
                                        
                                        {/* <div class="form-group">
                                            <div class="col-sm-12 text-center"> 
                                                <button type="submit" class="btn btn-success" style="width:20%;">Update</button>
                                            </div>
                                        </div>  */}

                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            // </div>
        );
    }
}
export default ProfilePage;
