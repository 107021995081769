import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js'
import {upload} from '../../../api-calls/upload'
import Form from "react-jsonschema-form";
import 'react-dropzone-uploader/dist/styles.css'


/* To update form follow documentation located here:
* https://react-jsonschema-form.readthedocs.io/en/latest/form-customization/
******/
const schema = {
  title: "",
  type: "object",
  required: ["special_name","special_description"],
  properties: {
    special_name: {type: "string", title: "Special Name"}, 
    special_description: {type: "string", title: "Special Description"},
  }
};

const ui_schema={
  "special_name": {
    "ui:placeholder":"Add Special Name",
    "ui:autofocus": true,
    "ui:emptyValue": ""
  },
  "special_description": {
    "ui:widget": "textarea",
    "ui:placeholder":"Add Special Description"
  },
}

class AddSpecial extends Component {
  constructor(props){
    super(props);
    this.state = {
          fileList:[],
          isUploading:false,
          uploadSuccess:false,
          result:null,
          errorString:null
   }
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
}

handleChangeStatus = ({ meta, file }, status) => { 
  if(status === 'done'){
    this.state.fileList.push(file);
  } else if(status === 'removed'){
     this.removeItem(file);
  }
}

removeItem(e) {
  var array = [...this.state.fileList]; 
  var index = array.indexOf(e)

  if (index !== -1) {
    array.splice(index, 1);
    this.setState({fileList: array});
  }
}

onSubmit = ({formData}, e) => {
  this.setState({isUploading:true});
  let fData = new FormData();
  
  fData.append('special_name', formData.special_name);
  fData.append('special_description', formData.special_description);

  //Upload Product to Server
  upload(fData).then(
      res => {
        this.setState({isUploading:false, uploadSuccess:true,result:res.data,errorString:null});
        this.props.callback();
      }
      ).catch(error=> {
        this.setState({isUploading:false, uploadSuccess:false,fileList:[],errorString:error});
      }
  )
}

render() {

        const backgroundCSS = {
            width: '100%'
        };

        const boxHeight ={
          width:'100%',
          height:'100%'
        }
        
       // if(!this.state.isUploading && !this.state.uploadSuccess){
          return (
            <div>
                {/*New Content Here*/}
                <div className="d-flex">
                    <div>
                        <h1>Add Specials</h1>
                        <h4></h4>
                    </div>
                </div>
                <div className="card shadow p-4" style={backgroundCSS}>

                <Form schema={schema}
                    uiSchema={ui_schema}
                    onChange={console.log("changed")}
                    onSubmit={this.onSubmit}
                    onError={console.log("errors")} 
                >
                  <div>
                    <button className="btn btn-danger" type="submit">Add Special</button>
                  </div>
                </Form>

              </div>
            </div>
          );
     // } 
      
      // else if(this.state.isUploading ){
      //   return(
      //     <div>
      //       <div className="card shadow p-4" style={boxHeight}>
      //       <Loading></Loading>
      //        Loading please wait...
      //       </div>
      //     </div>
      //   );
      // } else if(this.state.uploadSuccess){
      //   return(

      //     <div>
      //       <div className="card shadow p-4" style={boxHeight}>
      //           {this.state.result}
      //       </div>
      //     </div>
           
      //   );
      // }
      // else if(this.state.errorString !== null){
      //   return(

      //     <div>
      //       <div className="card shadow p-4" style={boxHeight}>
      //           Error Uploading: {this.state.errorString}
      //       </div>
      //     </div>
           
      //   );
      // }
    }
}

export default AddSpecial;
