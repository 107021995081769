import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './css/dashboard.css';
import {getGravatar} from '../../api-calls/user-api'

class Gravatar extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isVisible:true,
            gravUrl : ""
        };
    }

    getProfileIcon = () => {
        
        getGravatar().then(
            res => {
                this.setState({isVisible:true,gravUrl:res.data});
                }
            ).catch(error=> {
                this.setState({isVisible:false});
                
            }
        )
         
    };

    componentDidMount(){
        this.getProfileIcon();
    }

    render() {
            console.log(this.state.gravUrl);


        return (
            
                    <span>
                        <img className= "rounded-circle" src={this.state.gravUrl} width={this.props.width} height={this.props.height}/>
                    </span>

        );

    }
}

export default Gravatar;
