import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import {removeSpecials} from '../../api-calls/specials-api'

class EditSpecialCard extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isVisible : true
        };
    }

    removeItem = (itemId) => {
        console.log(itemId);
         if(itemId !==undefined && itemId!==null){
         //retrieve list of all products
         removeSpecials(itemId).then(
            res => {
                this.setState({isVisible:false})
                }
            ).catch(error=> {
            console.log(error);
            }
        )
         }
    };

    render() {

        const backgroundCSS = {
            width: '300px'
        };

        const cardCSS = {
            width: '400px'
        };

        const imageCSS = {
            width: '100%'
        };

        const buttonCSS = {
            width: '100%'
        };

        const priceCSS = {
            color: 'blue'
        };

        return (
            <React.Fragment>
                { this.state.isVisible ? 
                    <tr>
                        <td>
                            <h4 class="card-title">{this.props.title}</h4>
                        </td>
                        <td>
                            <div class="card-text">{this.props.description}</div>
                        </td>
                        <td>
                            <button onClick={()=>this.removeItem(this.props.id)}  className="btn btn-danger">Remove</button>
                        </td>
                    </tr>

                    : null 
                }
            </React.Fragment>
        );

    }
}

export default EditSpecialCard;
