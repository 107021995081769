import axios from 'axios';
import {createAuthorizationTokenHeader} from './auth';

const protocol="https://";
const url="rambl.in";
const port ="8333";
/**
* Authorize user
**/
export const upload = (formData) => {

  const req = axios.post(protocol+url+':'+port+'/bullards-api/addSpecial',
              formData,
              {
                headers: createAuthorizationTokenHeader()
              }
  ).then(res => {
        return res;
    }
  ).catch(error=> {
    // handle error
    throw error;
  })

  return req


}
