import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import {approveReview} from '../../api-calls/review-api';
import Loading from './loading';

class EditReviewCard extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isVisible : true
        };
    }


  
    
    removeItem = (itemId) => {
        
        //  if(itemId !==undefined && itemId!==null){
        //     removeReview(itemId).then(
        //         res => {
        //             this.setState({isVisible:false})
        //         }
        //         ).catch(error=> {
        //             console.log(error);
        //         }
        //     )
        //  }
    };

    approveReview = (itemId) => {
        if(itemId !==undefined && itemId!==null){
            approveReview(itemId).then(
                res => {
                    this.setState({isVisible:false})
                }
                ).catch(error=> {
                    console.log(error);
                }
            )
         }
    };

    render() {

        const backgroundCSS = {
            width: '300px'
        };

        const cardCSS = {
            width: '400px'
        };

        const imageCSS = {
            width: '100%'
        };

        const buttonCSS = {
            width: '100%'
        };

        const priceCSS = {
            color: 'blue'
        };

        return (
            <React.Fragment>
                { this.state.isVisible ? 
                    <tr>
                        <td>
                            <h4 class="card-title">{this.props.name}</h4>
                        </td>
                        <td>
                            <div class="card-text">{this.props.rating}</div>
                        </td>
                        <td>
                            <div class="card-text">{this.props.review}</div>
                        </td>
                        <td>
                            <button onClick={()=>this.approveReview(this.props.id)}  className="btn btn-outline-success">Approve Review</button>
                            {/* <button onClick={()=>this.removeItem(this.props.id)}  className="btn btn-outline-danger">Remove Review</button> */}
                        </td>
                    </tr>

                    : null 
                }
            </React.Fragment>
        );

    }
}

export default EditReviewCard;
