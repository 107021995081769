import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import {getAccessToken} from '../../../api-calls/google-api'
import { GoogleProvider, GoogleDataChart } from 'react-analytics-widget';
import '../css/global.css';

;(function(w, d, s, g, js, fjs) {
    g = w.gapi || (w.gapi = {})
    g.analytics = {
      q: [],
      ready: function(cb) {
        this.q.push(cb)
      }
    }
    js = d.createElement(s)
    fjs = d.getElementsByTagName(s)[0]
    js.src = "https://apis.google.com/js/platform.js"
    fjs.parentNode.insertBefore(js, fjs)
    js.onload = function() {
      g.load("analytics")
    }
  })(window, document, "script")
const CLIENT_ID = '702748764907-bqht714p60u9q0e7nr269hc3b20ogs7k.apps.googleusercontent.com';

// graph 1 config
const last30days = {
  reportType: "ga",
  query: {
    dimensions: "ga:date",
    metrics: "ga:pageviews",
    "start-date": "30daysAgo",
    "end-date": "yesterday"
  },
  chart: {
    type: "LINE",
    options: {
      title: "Last 30 days pageviews"
    }
  }
}

// graph 2 config
const last7days = {
  reportType: "ga",
  query: {
    dimensions: "ga:date",
    metrics: "ga:pageviews",
    "start-date": "7daysAgo",
    "end-date": "yesterday"
  },
  chart: {
    type: "LINE",
    options: {
      title: "Last 7 days pageviews"
    }
  }
}

// graph 3 config
const sourceComingFrom = {
  reportType: "ga",
  query: {
    dimensions: "ga:source",
    metrics: "ga:pageviews",
    "start-date": "30daysAgo",
    "end-date": "yesterday"
  },
  chart: {
    type: "PIE",
    options: {
      title: "Visit's From Source 30 Days"
    }
  }
}


const dailyByType = {
  reportType: "ga",
  query: {
    dimensions: "ga:deviceCategory",
    metrics: "ga:pageviews",
    "start-date": "yesterday",
    "end-date": "today"
  },
  chart: {
    type: "TABLE",
    options: {
      title: "Visits Today By Device Type"
    }
  }
}

// analytics views ID
const views = {
  query: {
    ids: "ga:208193655"
  }
}


const moveRight = {
	
};

class OverviewPage extends Component {


    constructor(props) {
      super(props);
      this.state = { 
          token : ''
      };
    }

    componentDidMount = () => {
      getAccessToken().then(
          res => 
            {
              this.setState({token:res.data});
            }
          ).catch(error=> {
            console.log(error);
          }
        )
      
    }

    getlast30days= () =>{
      if(this.state.token !==undefined && this.state.token !==null && this.state.token !==""){
        return (
              <div>
                <GoogleProvider accessToken={this.state.token}>
                  <GoogleDataChart views={views} config={last30days} style={moveRight} />
                </GoogleProvider>
              </div>
        )
      }else{
        return (
          <div>
            Data Currently Unavailable
          </div>
         )
      }
    }

    getlastWeek= () =>{
      if(this.state.token !==undefined && this.state.token !==null && this.state.token !==""){
        return (
              <div>
                <GoogleProvider accessToken={this.state.token}>
                  <GoogleDataChart views={views} config={last7days} style={moveRight}/>
                </GoogleProvider>
              </div>
        )
      }else{
        return (
          <div>
            Data Currently Unavailable
          </div>
         )
      }
    }

    dailyVisitByType= () =>{
      if(this.state.token !==undefined && this.state.token !==null && this.state.token !==""){
        return (
              <div>
                <GoogleProvider accessToken={this.state.token}>
                  <GoogleDataChart views={views} config={dailyByType} />
                </GoogleProvider>
              </div>
        )
      }else{
        return (
          <div>
            Data Currently Unavailable
          </div>
         )
      }
    }


    getVisitsFromSource= () =>{
      if(this.state.token !==undefined && this.state.token !==null && this.state.token !==""){
        return (
              <div class="overflow-hidden">
                <GoogleProvider accessToken={this.state.token}>
                  <GoogleDataChart views={views} config={sourceComingFrom} style={moveRight}/>
                </GoogleProvider>
              </div>
        )
      }else{
        return (
          <div>
            Data Currently Unavailable
          </div>
         )
      }
    }
 
    render() {
		const boxCSS = {
            minHeight: '350px'
		};
		
        const mdBoxCSS = {
            background: 'white',
            minHeight: '350px',
            width: '100%',
            textAlign: 'center',
            borderRadius: '5px'
        };

        const lgBoxCSS = {
            background: 'white',
            minHeight: '500px',
            width: '100%',
            textAlign: 'center',
            borderRadius: '5px'
        };

        return (
            <div>
                <div class="d-flex flex-column">
                    <div class="p-4">
                        <div>
                            <h1>Overview</h1>
                        </div>
						<div>
							<div class="d-flex flex-column flex-lg-row flex-wrap">
								<div class="pb-4 w-100 pr-lg-2 w-lg-50">
									<div class="d-flex justify-content-center align-items-center card shadow p-4 overflow-hidden" style={boxCSS}>									
										<div>Visits Today By Device Type</div>
										<div>{this.dailyVisitByType()}</div>
									</div>
								</div>
								<div class="pb-4 w-100 pl-lg-2 w-lg-50">
									<div class="d-flex justify-content-center align-items-center card shadow p-4 overflow-hidden" style={boxCSS}>
										<div>{this.getVisitsFromSource()}</div>
									</div>
								</div>
								<div class="pb-4 w-100 pr-lg-2 w-lg-50">
									<div class="d-flex justify-content-center align-items-center card shadow p-4 overflow-hidden" style={boxCSS}>
										<div>{this.getlastWeek()}</div>
									</div>
								</div>
								<div class="w-100 pl-lg-2 w-lg-50">
									<div class="d-flex justify-content-center align-items-center card shadow p-4 overflow-hidden" style={boxCSS}>
										<div>{this.getlast30days()}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        );
    } 
}
export default OverviewPage;
