import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js'
import {upload} from '../../../api-calls/upload'
import 'react-dropzone-uploader/dist/styles.css'
import AddSpecial from '../actions/add-special';
import EditInventory from '../actions/edit-inventory';

const schema = {
    title: "",
    type: "object",
    required: ["special_name","special_description"],
    properties: {
        special_name: {type: "string", title: "Special Name"}, 
        special_description: {type: "string", title: "Special Description"},
    }
};

const ui_schema={
    "special_name": {
        "ui:placeholder":"Add Special Name",
        "ui:autofocus": true,
        "ui:emptyValue": ""
    },
    "special_description": {
        "ui:widget": "textarea",
        "ui:placeholder":"Add Special Description"
    }
};

class SpecialsPage extends Component {
    constructor(props){
        super(props);
        this.state = {
              fileList:[],
              isUploading:false,
              uploadSuccess:false,
              result:null,
              errorString:null,
              updatedTriggerCount:0
       }
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChangeStatus = ({ meta, file }, status) => { 
        if(status === 'done'){
            this.state.fileList.push(file);
        } else if(status === 'removed'){
            this.removeItem(file);
        }
    }
      
    removeItem(e) {
        var array = [...this.state.fileList]; 
        var index = array.indexOf(e)

        if (index !== -1) {
            array.splice(index, 1);
            this.setState({fileList: array});
        }
    }

    onSubmit = ({formData}, e) => {
        this.setState({isUploading:true});
        let fData = new FormData();

        fData.append('special_name', formData.special_name);
        fData.append('special_description', formData.special_description);

        //Upload Product to Server
        upload(fData).then(
            res => {
                this.setState({isUploading:false, uploadSuccess:true,result:res.data,errorString:null});
            }
            ).catch(error=> {
                this.setState({isUploading:false, uploadSuccess:false,fileList:[],errorString:error});
            }
        )
    }

    updateInventory= () =>{
        var startNum = this.state.updatedTriggerCount
        var currCnt = startNum + 1; 
        console.log("UPDATE TO " + currCnt);
        this.setState({updatedTriggerCount:currCnt});
    }

    render() {
        return (
            <div>
                <div class="d-flex">
                    <div>
                       
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <div class="p-4">
                        <AddSpecial callback={this.updateInventory}></AddSpecial>
                    </div>
                    <div class="px-4">
                        <EditInventory updatedCount={this.state.updatedTriggerCount}></EditInventory>
                    </div>
                </div>
            </div>
        );
    }
}
export default SpecialsPage;
