import axios from 'axios';

/**
 * ABSOLUTELY REQURED
 */
var TOKEN_KEY = 'jwtToken';

/**
* Authorize user
**/
export const authUser = (user,pwd) => {

  const req = axios.post('https://rambl.in:8333/auth/login', {
     username: user,
     password: pwd,
   },
    {
      headers: createAuthorizationTokenHeader()
    }
  ).then(res => {
        console.log(res.data);
        setJwtToken(res.data.access_token);
        return res;
       }
  ).catch(error=> {
    // handle error
    removeJwtToken();
    throw error;
  })
  return req
}

export const logOut = ()=>{
  removeJwtToken();
}

export const createAuthorizationTokenHeader = () => {
    var token = getJwtToken();
    if (token) {
        return {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        };
    } else {
        return {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        };
    }
}

export const isAuth = () =>{

  var token = getJwtToken();
   
  const req = axios.post('https://rambl.in:8333/auth/refresh','',
  {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }
  ).then(res => {
    console.log(res.data);
    if(res.data !==undefined && res.data !== null && res.data.access_token !== null){
      setJwtToken(res.data.access_token);
      return true;
    }else{
      removeJwtToken();
      return false;
    }
    
  }
  ).catch(error=> {
  // handle error
    removeJwtToken();
    return false;
  })


  
 return req
}

export const getJwtToken = () =>{
  return localStorage.getItem(TOKEN_KEY);
};


export const setJwtToken = (token) => {
      localStorage.setItem(TOKEN_KEY, token);
};

export const removeJwtToken = () => {
      localStorage.removeItem(TOKEN_KEY);
};
