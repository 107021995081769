import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js'
import { Link } from 'react-router-dom';
import {upload} from '../../../api-calls/upload'
import Form from "react-jsonschema-form";
import 'react-dropzone-uploader/dist/styles.css'
import Loading from '../loading'

const cssTitle_1 = {
    color: '#5CA750',
    textAlign: 'center'
};

class CateringPage extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div>

                <div class="d-flex">
                    <div>
                        <h1>Catering Page</h1>
                        <h4>http://mychefcateringphilly.com/</h4>
                    </div>
                </div>

                <div class="d-flex p-4">
                    <div class="card p-4 w-100">

                        <div><h1 class="text-center">LOGO</h1></div>

                        <div><h4 class="py-2" style={cssTitle_1}><span class="text-uppercase">New Menu For Delivery Weeks Of </span><span class="font-weight-bold">2.2.20</span> and <span class="font-weight-bold">2.9.20</span></h4></div>

                        <div>
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col">Qty.</th>
                                        <th scope="col">Card|No Carb</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>New Philly Cheese Steak Stuffed Mushroom or Pepper</td>
                                        <td>$15.00</td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-25" readonly/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-50" readonly/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>New Philly Cheese Steak Stuffed Mushroom or Pepper</td>
                                        <td>$15.00</td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-25" readonly/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-50" readonly/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>New Philly Cheese Steak Stuffed Mushroom or Pepper</td>
                                        <td>$15.00</td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-25" readonly/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-50" readonly/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>New Philly Cheese Steak Stuffed Mushroom or Pepper</td>
                                        <td>$15.00</td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-25" readonly/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-50" readonly/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>New Philly Cheese Steak Stuffed Mushroom or Pepper</td>
                                        <td>$15.00</td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-25" readonly/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-50" readonly/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>New Philly Cheese Steak Stuffed Mushroom or Pepper</td>
                                        <td>$15.00</td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-25" readonly/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm w-50" readonly/>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div><h4 class="py-2" style={cssTitle_1}><span class="text-uppercase">Bulk Packaged Items</span></h4></div>

                        <div>
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">First</th>
                                        <th scope="col">Last</th>
                                        <th scope="col">Handle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Jacob</td>
                                        <td>Thornton</td>
                                        <td>@fat</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td colspan="2">Larry the Bird</td>
                                        <td>@twitter</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div><h5 class="font-weight-bold pt-2">Family Meals - Serves 4-5</h5></div>

                        <div>
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">First</th>
                                        <th scope="col">Last</th>
                                        <th scope="col">Handle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Jacob</td>
                                        <td>Thornton</td>
                                        <td>@fat</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td colspan="2">Larry the Bird</td>
                                        <td>@twitter</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div><h5 class="font-weight-bold pt-2">Desserts / Breakfast</h5></div>

                        <div>
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">First</th>
                                        <th scope="col">Last</th>
                                        <th scope="col">Handle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Jacob</td>
                                        <td>Thornton</td>
                                        <td>@fat</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td colspan="2">Larry the Bird</td>
                                        <td>@twitter</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div><p>gf: Gluten Free</p></div>

                        <div><h5 class="text-center py-2">food that fits into any lifestyle</h5></div>

                        <div>
                            <div class="text-center">http://mychefcateringphilly.com/</div>
                            <div class="text-center">610.844.5900 | karen@mychefcateringphilly.com</div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
export default CateringPage;
