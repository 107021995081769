import axios from 'axios';
import {createAuthorizationTokenHeader} from './auth';

/**
* Get All User Details
**/
export const getUserDetails = () => {

  const req = axios.get('https://rambl.in:8333/auth/getUserDetails',
              {
                headers: createAuthorizationTokenHeader()
              }
  ).then(res => {
        return res;
    }
  ).catch(error=> {
    throw error;
  })
  return req
}



/**
* Get All User Details
**/
export const getGravatar = () => {

    const req = axios.get('https://rambl.in:8333/api/getGravatarUrl',
                {
                  headers: createAuthorizationTokenHeader()
                }
    ).then(res => {
          return res;
      }
    ).catch(error=> {
      throw error;
    })
    return req
  }
