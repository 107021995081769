import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { isAuth } from "../api-calls/auth";

/**
 * ABSOLUTELY REQUIRED DO NO MODIFY
 */
class PrivateRoute extends Component {
  state = {
    hasAccess: false,
    loaded: false,
  }

  componentDidMount() {
    this.checkAccess();
  }


  /**
   * Check if user is Authenticated.
   */
  checkAccess = () => {
 
    isAuth()
      .then(isAuthenticated => {
        this.setState({
          hasAccess :isAuthenticated,
          loaded:true
        });
      })
      .catch(() => {
        this.props.history.push("/login");
      });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, hasAccess } = this.state;
    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={props => {
          return hasAccess ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          );
        }}
      />
    );
  }
}

export default withRouter(PrivateRoute);

