import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardLink from './DashboardLink'
import Gravatar from './Gravatar'

import {DASHBOARD_FILTERS} from '../../redux/dashboardConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff,faFileContract,faTachometerAlt,faUtensils,faEdit } from '@fortawesome/free-solid-svg-icons'
import './css/dashboard.css';


const faBtnCSS = {
    marginRight: '25px',
    '&:hover': {
                 backgroundColor: '#000000',
                 color: 'white'
               }
};


//DASHBOARD LINK ITEMS ARE HERE
export const mainListItems = (
    <div>
      <DashboardLink filter={DASHBOARD_FILTERS.OVERVIEW}>     
      <div className="p-2 list-item"> 
          
          <FontAwesomeIcon className="list-item-text" icon={faTachometerAlt} />        
            <span className="list-item-text"> Overview</span>
          
      </div>
      </DashboardLink>
      <DashboardLink filter={DASHBOARD_FILTERS.SPECIALS}> 
      <div className="p-2 list-item" >
             
            <FontAwesomeIcon className="list-item-text" icon={faUtensils} />         
            <span className="list-item-text"> Specials</span>
          
      </div>
      </DashboardLink>
      <DashboardLink filter={DASHBOARD_FILTERS.REVIEWS}> 
      <div className="p-2 list-item" >
             
            <FontAwesomeIcon className="list-item-text" icon={faEdit} />         
            <span className="list-item-text"> Reviews</span>
          
      </div>
      </DashboardLink>
      <DashboardLink filter={DASHBOARD_FILTERS.PROFILE}>   
      <div className="p-2 list-item" >
          
            <Gravatar className="profile-icon" width="20px" height="20px"/>
            <span className="list-item-text"> Profile</span>
          
      </div>
      </DashboardLink>
      {/* <div className="p-2 list-item" >
          <DashboardLink filter={DASHBOARD_FILTERS.CATERING}>            
            <span className="list-item-text">Catering</span>
          </DashboardLink>
      </div> */}
      <DashboardLink filter={DASHBOARD_FILTERS.CONTRACT}> 
      <div className="p-2 list-item" >
               
          <FontAwesomeIcon className="list-item-text" icon={faFileContract} />        
            <span className="list-item-text"> Contract</span>
          
      </div>
      </DashboardLink>
      <DashboardLink filter={DASHBOARD_FILTERS.LOGOUT}> 
      <div className="p-2 list-item" >
          
          {/* <span>Logout </span> */}
          <FontAwesomeIcon className="list-item-text" icon={faPowerOff} />     
          <span className="list-item-text"> Logout</span>
         
      </div>
      </DashboardLink>
    </div>
);


//DASHBOARD LINK ITEMS ARE HERE
export const mobileMenu = (
  <div>
   <div>
        <DashboardLink filter={DASHBOARD_FILTERS.OVERVIEW}>            
          <span className="">Overview</span>
        </DashboardLink>
   </div>
 
    <div>
        <DashboardLink filter={DASHBOARD_FILTERS.SPECIALS}>            
          <span className="">Specials</span>
        </DashboardLink>
    </div>
    <div>
        <DashboardLink filter={DASHBOARD_FILTERS.REVIEWS}>            
          <span className="">Reviews</span>
        </DashboardLink>
    </div>
    <div>
        <DashboardLink filter={DASHBOARD_FILTERS.PROFILE}>            
          <span className="">Profile</span>
        </DashboardLink>
    </div>
    <div >
        <DashboardLink filter={DASHBOARD_FILTERS.CATERING}>            
          <span className="">Catering</span>
        </DashboardLink>
    </div> 
    <div>
        <DashboardLink filter={DASHBOARD_FILTERS.CONTRACT}>            
          <span className="">Contract</span>
        </DashboardLink>
    </div>
    <div>
        <DashboardLink filter={DASHBOARD_FILTERS.LOGOUT}> 
          {/* <span>Logout </span> */}
          <FontAwesomeIcon className="" icon={faPowerOff} />
          <span>Logout</span>
        </DashboardLink>
    </div>
    </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
