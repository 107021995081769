import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-dropzone-uploader/dist/styles.css';
import EditReviewCard from '../edit-review-card';
import {getAllUnapprovedComments} from '../../../api-calls/review-api';
import Loading from '../loading';

class ReviewPage extends Component {
  
    constructor(props){
        super(props);
        this.state = {
              unapprovedReviews:[],
              isLoading:true
        }

        this.generateUnapprovedReviewsList = this.generateUnapprovedReviewsList.bind(this);
    }
    
    componentDidMount(){
        getAllUnapprovedComments().then(
            res => {
                let data = res;
                if(res !==undefined && res !== null){
                    data = res.data;
                }
                this.setState({unapprovedReviews:data , isLoading:false});
                
                console.log(this.state.unapprovedReviews);
                }
            ).catch(error=> {
                console.log(error);
            }
        )
    }
    generateTable=()=>{
        if(this.state.isLoading){
            return(<div>
                        <Loading></Loading>
                    </div>);
        }else{
            return(<div>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Rating</th>
                            <th scope="col">Review</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.generateUnapprovedReviewsList()}
                    </tbody>
                </table>
            </div>);
            
        }

      }
    generateUnapprovedReviewsList(){
       return( <React.Fragment>
                {this.state.unapprovedReviews.map((unapprovedReviews, index) => (
                    <EditReviewCard name={unapprovedReviews.name} 
                                    rating = {unapprovedReviews.rating}
                                    review = {unapprovedReviews.review}
                                    id={unapprovedReviews.id}
                                    key={unapprovedReviews.name+index}>
                    </EditReviewCard>
                ))}
            </React.Fragment>);
    }

    render() {
        return (
            <div>
                <div class="d-flex">
                    <div>
                       
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <div class="p-4">
                        {this.generateTable()}
                    </div>
                   
                </div> 
            </div>
        );
    }
}
export default ReviewPage;