import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Login from './pages/login';
import Dashboard from './pages/dashboard'
import DashboardNew from './pages/dashboard-new'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from './redux/store'

import PrivateRoute from './helpers/PrivateRoute';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js'

ReactDOM.render(
 
   <Provider store={store}>
     <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/login" component={Login} />
          <PrivateRoute exact path="/dashboard" component={Dashboard}/>
          <PrivateRoute  exact path="/dashboard-new" component={DashboardNew}/>
          <Redirect to="/" />
       </Switch>
     </BrowserRouter>
    </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
