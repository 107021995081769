import React, {Component} from 'react';
import LoginCard from '../components/login-card/login-card';

/**
 * ABSOLUTELY REQUIRED
 */
class Login extends Component {
    render() {

        const backgroundCSS = {
            height: '100vh'
        };
        
        return (
            <div class="d-flex justify-content-center align-items-center" style={backgroundCSS}>
                <LoginCard></LoginCard>
            </div>
        );
    }
}

export default Login;
