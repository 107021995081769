import { SET_DASHBOARD_STATE,SET_LOGIN_STATE } from "./actionTypes";

export const setFilter = filter => (

  {
    type: SET_DASHBOARD_STATE, payload: { filter }
  }

);


export const setLoginState = filter => (

  {
    type: SET_LOGIN_STATE, payload: { filter }
  }

);
