import { SET_DASHBOARD_STATE } from "../actionTypes";
import { DASHBOARD_FILTERS } from "../dashboardConstants";

const initialState = DASHBOARD_FILTERS.OVERVIEW;

const dashboardFilter = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_STATE: {
      return       {
        ...state,
        filter: action.payload.filter
      }

    }
    default: {
      return state;
    }
  }
};

export default dashboardFilter;
