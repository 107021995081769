import axios from 'axios';
import {createAuthorizationTokenHeader} from './auth';

const protocol="https://";
const url="rambl.in";
const port ="8333";
/**
* Authorize user
**/
export const getAccessToken = () => {

  const req = axios.get(protocol+url+':'+port+'/bullards-api/getGoogleApiKey',
        {
            headers: createAuthorizationTokenHeader()
        }
  ).then(res => {
        return res;
    }
  ).catch(error=> {
    throw error;
  })
  return req
}